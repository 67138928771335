console.log('🥑 %cMade by Monk', 'background: #616a2e; color: #f4e9e2; padding: 5px 17px; border-radius: 3px;');
console.log(' %chttps://monk.com.au ', 'padding: 5px 13px;');

import './skip-link-focus-fix';
import './smoothscroll';
import './navigation';
import './scrollyclasses';
import './viewport-height';
import './fw-video';
import './sliders';
import './accordions';
import './fancybox';
import './gsapanims';

jQuery(function ($) {

	//page
	var $hamburger = $(".hamburger"),
		$site = $("body"),
		$menu = $(".main-navigation"),
		$menuitems = $(".menu-item"),
		$screenOverlay = $(".screen-overlay");

	/*
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	Mobile Menu
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	*/
	$hamburger.on("click", function () {
		$hamburger.toggleClass("is-active");
		$site.toggleClass("menu-open");
		// if ( $('body').hasClass('menu-open') ) {
		// 	$('#mobile-menu .label').text('Close')
		// } else {
		// 	$('#mobile-menu .label').text('Menu')
		// }
	});

	//close menu with an outside click (basically anywhere on .site-content)
	function closeMenu() {
		$site.removeClass("menu-open");
		$menu.removeClass("toggled");
		$menuitems.removeClass('toggled-on');
		$hamburger.removeClass("is-active");
	}

	$screenOverlay.on('click', closeMenu);

	$(document).on('keydown', function (e) {
		if (e.which == 27) {
			closeMenu();
		}
	});

	/*
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	Animate elements in on scroll
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	*/
	// $('.animate').on('inview', function (event, isInView) {
	// 	if (isInView) {
	// 		$(this).addClass('fadein');
	// 	}
	// });
	// AOS.init();

    for (let i = 0; i < 5; i++) {
        $('.scroll-bar.top p').clone().appendTo('.scroll-bar.top');
        $('.scroll-bar.bottom p').clone().appendTo('.scroll-bar.bottom');
    }


    /* Home Page Background Location Swaps */


    $( ".home-locate .post-477 h1" )
    .on( "mouseenter", function() {
        if (!$("#home-1 .wp-block-query").hasClass("highgate")) {
            $("#home-1 .wp-block-query").addClass("highgate");
        }
    } )
    .on( "mouseleave", function() {
        if ($("#home-1 .wp-block-query").hasClass("highgate")) {
            $("#home-1 .wp-block-query").removeClass("highgate");
        }
    } );

    $( ".home-locate .post-487 h1" )
    .on( "mouseenter", function() {
        if (!$("#home-1 .wp-block-query").hasClass("citybeach")) {
            $("#home-1 .wp-block-query").addClass("citybeach");
        }
    } )
    .on( "mouseleave", function() {
        if ($("#home-1 .wp-block-query").hasClass("citybeach")) {
            $("#home-1 .wp-block-query").removeClass("citybeach");
        }
    } );

    $( ".home-locate .post-488 h1" )
    .on( "mouseenter", function() {
        if (!$("#home-1 .wp-block-query").hasClass("claremont")) {
            $("#home-1 .wp-block-query").addClass("claremont");
        }
    } )
    .on( "mouseleave", function() {
        if ($("#home-1 .wp-block-query").hasClass("claremont")) {
            $("#home-1 .wp-block-query").removeClass("claremont");
        }
    } );

    $( ".home-locate .post-484 h1" )
    .on( "mouseenter", function() {
        if (!$("#home-1 .wp-block-query").hasClass("westleed")) {
            $("#home-1 .wp-block-query").addClass("westleed");
        }
    } )
    .on( "mouseleave", function() {
        if ($("#home-1 .wp-block-query").hasClass("westleed")) {
            $("#home-1 .wp-block-query").removeClass("westleed");
        }
    } );

    $( ".home-locate .post-486 h1" )
    .on( "mouseenter", function() {
        if (!$("#home-1 .wp-block-query").hasClass("qv1")) {
            $("#home-1 .wp-block-query").addClass("qv1");
        }
    } )
    .on( "mouseleave", function() {
        if ($("#home-1 .wp-block-query").hasClass("qv1")) {
            $("#home-1 .wp-block-query").removeClass("qv1");
        }
    } );

    $( ".home-locate .post-485 h1" )
    .on( "mouseenter", function() {
        if (!$("#home-1 .wp-block-query").hasClass("allendale")) {
            $("#home-1 .wp-block-query").addClass("allendale");
        }
    } )
    .on( "mouseleave", function() {
        if ($("#home-1 .wp-block-query").hasClass("allendale")) {
            $("#home-1 .wp-block-query").removeClass("allendale");
        }
    } );

});